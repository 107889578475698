import Button from './Button';
import Image from './Image';
import Search from './Search';
import Select from './Select';
import Table from './Table';
import Input from './Input';
import RadioInput from './RadioInput';
import TextArea from './TextArea';
import CustomSearch from './CustomSearch';
import Checkbox from './Checkbox';
import Pagination from './Pagination'
import AvailableBadge from './AvailableBadge'
import PendingBadge from './PendingBadge'
import UnavailableBadge from './UnavailableBadge'
import Badge from './Badges'
import CustomImages from './CustomImages'
const atoms = {
    Button,
    Image,
    Search,
    Select,
    Table,
    Input,
    RadioInput,
    TextArea,
    CustomSearch,
    Checkbox,
    Pagination,
    AvailableBadge,
    UnavailableBadge,
    Badge,
    CustomImages,
    PendingBadge
}
export default atoms