import React, { useContext } from 'react'
import atoms from '../../atoms'
import { useNavigate } from 'react-router'
import { numberWithCommas, checkPermissionsInArray } from '../../../constants/utils'
import { AllPermissions } from '../../../constants/permission'
import { Context } from '../../../Context'

const { Button } = atoms
const Index = ({ dashboardDetails }: any) => {
  const navigate = useNavigate()
  const [userPermission, setUserPermission] = useContext<string[]>(Context);

  return (
    <div>
      <div className="row mt-md-3 mt-1">
        <div className='col-lg-8'>
          <div className='row'>
         { checkPermissionsInArray(AllPermissions.Dashboard_Pending_Order) &&  <div className="col-md-6 mt-0 mt-sm-0 mt-md-2 mt-lg-0 card-padding">
              <div className="card-dashboard mx-auto cursor-pointer"
                // style={{ width:'24rem' }}
                onClick={() => { userPermission?.includes(AllPermissions.Pending_Order_Tab) && navigate('/pending-receipt') }}
              >
                <div className="card-body">
                  <div className='fs-md-24 fs-20 fw-600'>Pending Orders</div>
                  <div className="d-flex justify-content-between ">
                    <div>
                      <p className="card-text fs-md-16 fs-14 fw-400 ">No. of pending orders</p>
                      <p className="card-title fs-md-14 fs-12 fw-600 ">{dashboardDetails.pending_orders}</p>
                    </div>
                    <div className="icon-container ">
                      <i className="fas fa-shopping-cart"></i>
                    </div>
                  </div>
                  <hr className='line-margin'></hr>
                  <div>
                    <p className="card-text fs-md-16 fs-14 fw-400 ">Total Value</p>
                    <>{console.log('dashboardDetails?.pending_total_value', dashboardDetails?.pending_total_value)}</>
                    <p className="card-title fs-md-14 fs-12 fw-600 ">₹ {numberWithCommas(Number(dashboardDetails?.pending_total_value))}</p>
                  </div>
                  <hr className='line-margin'></hr>
                  <div>
                    <p className="card-text fs-md-16 fs-14 fw-400 ">Total Amount receivable</p>
                    <p className="card-title fs-md-14 fs-12 fw-600 ">₹ {numberWithCommas(Number(dashboardDetails?.pending_amount_receivable))}</p>
                  </div>
                </div>
              </div>
            </div>}

          { checkPermissionsInArray(AllPermissions.Dashboard_Inventory_Audit) && <div className="col-md-6 mt-0 mt-md-2 mt-lg-0 card-padding">
              <div className="card-dashboard mx-auto cursor-pointer"
                // style={{ width:'24rem'  }}
                onClick={() => { userPermission?.includes(AllPermissions.Inventory_Audit_Tab) && navigate('/inventory-audit') }}>
                <div className="card-body">
                  <div className='fs-md-24 fs-20 fw-600'>Inventory Audit</div>
                  <div className="d-flex justify-content-between ">
                    <div>
                      <p className="card-text fs-md-16 fs-14 fw-400 ">Today</p>
                      <p className="card-title fs-md-14 fs-12 fw-600 ">{dashboardDetails.today_audited} </p>
                    </div>
                    <div className="icon-container ">
                      <i className="fas fa-shopping-cart"></i>
                    </div>
                  </div>
                  <hr className='line-margin'></hr>
                  <div>
                    <p className="card-text fs-md-16 fs-14 fw-400 ">This Month</p>
                    <p className="card-title fs-md-14 fs-12 fw-600 ">{dashboardDetails.month_audited}</p>
                  </div>
                </div>
              </div>
            </div>}
          </div>
        </div>

        {checkPermissionsInArray(AllPermissions.Dashboard_My_Performance) && <div className='col-lg-4'>
          <div className='row'>
            <div className="col-md-6 col-lg-12 mt-0 mt-md-2 mt-lg-0 card-padding">
              <div className="card-dashboard mx-auto"
              // style={{ width:'24rem'  }}
              >
                <div className="card-body">
                  <div className='fs-md-24 fs-20 fw-600'>My Performance</div>
                  <div className="d-flex justify-content-between ">
                    <div>
                      <p className="card-text fs-md-16 fs-14 fw-400">Orders Taken Today</p>
                      <p className="card-title fs-md-14 fs-12 fw-600 ">{dashboardDetails?.my_current_day_total_orders} | ₹ {numberWithCommas(Number(dashboardDetails.my_current_day_orders_value))}</p>
                      <p className="card-text fs-10">Total Orders | Orders Value</p>

                    </div>
                    <div className="icon-container ">
                      <i className="fas fa-shopping-cart"></i>
                    </div>
                  </div>
                  <hr className='line-margin'></hr>
                  <div className=''>
                    <p className="card-text fs-md-16 fs-14 fw-400">Orders Taken This Month</p>
                    <p className="card-title fs-md-14 fs-12 fw-600 ">{dashboardDetails?.my_current_month_total_orders} | ₹ {numberWithCommas(Number(dashboardDetails.my_current_month_orders_value))}</p>
                    <p className="card-text fs-10">Total Orders | Orders Value</p>
                  </div>
                  <hr className='line-margin'></hr>
                  <div>
                    <p className="card-text fs-md-16 fs-14 fw-400 ">Total Inventory audited</p>
                    <p className="card-title fs-md-14 fs-12 fw-600 ">{dashboardDetails.my_today_audited} | {dashboardDetails.my_month_audited}</p>
                    <p className="card-text fs-10">Today | This Month</p>
                  </div>
                </div>
              </div>
            </div>
            <div className='d-lg-none col-6'></div>
          </div>

        </div>}
      </div>
    </div>
  )
}

export default Index