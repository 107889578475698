import React from 'react'
import './AvailableBadge.scss'
const Index = (props: { title?: string, isAvailable?:boolean }) => {
    const { title,isAvailable } = props
    return (
        <div className={`badge-product-sale ${isAvailable ? 'unavailable' : 'available'}`}>
            <span className="new">{`${title ? title : 'Available At Store'}`}</span>
        </div>
    )
}

export default Index